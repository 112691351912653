import React from 'react'
import PropTypes from 'prop-types'

import ViewSvg from '@/components/svg/view'
import ContactDetail from './contact-detail'

const Content = ({
  displayName,
  companyNamePresent,
  contactName,
  address,
  contactDetails,
  canViewContact,
  url
}) => (
  <div>
    <div className='c-popup__header'>
      <h5 className='c-popup__title'>
        <strong>{displayName}</strong>
      </h5>
      {companyNamePresent && (
        <div className='c-popup__subtitle'>
          <strong>{contactName}</strong>
        </div>
      )}
      <div className='c-popup__subtitle'>
        {!address || address === 'No address'
          ? (
            <span className='c--metal'>
              No address
            </span>
            )
          : (
            <span>
              {address}
            </span>
            )}
      </div>
    </div>

    <div className='c-popup__body'>
      <ul className='menu__item'>
        <ul className='c-popup__body--scroll'>
          {contactDetails && (
            contactDetails.map(line => (
              <ContactDetail key={line.id} label={line.label} value={line.value} />
            ))
          )}
        </ul>
        {canViewContact && (
          <span>
            {contactDetails.length > 0 && (
              <li className='menu__item-divide' />
            )}
            <li>
              <a href={url}>
                <ViewSvg className='icon' />
                View full profile
              </a>
            </li>
          </span>
        )}
      </ul>
    </div>
  </div>
)

Content.defaultProps = {
  contactName: null,
  companyNamePresent: false,
  contactDetails: [],
  canViewContact: false
}

Content.propTypes = {
  displayName: PropTypes.string.isRequired,
  companyNamePresent: PropTypes.bool,
  contactName: PropTypes.string,
  address: PropTypes.string.isRequired,
  contactDetails: PropTypes.array,
  canViewContact: PropTypes.bool,
  url: PropTypes.string.isRequired
}

export default Content
