import React from 'react'
import PropTypes from 'prop-types'

import AnimatedSpinnerSvg from '@/components/svg/animated-spinner'
import FileInputButton from './file-input-button'
import AddSvg from '@/components/svg/add'

const FileInput = ({ uploader, buttonClass, includeIcon, label, uploading, iconColorClass }) => {
  const handleUpload = event => {
    const files = Array.from(event.target.files)

    files.forEach(file => {
      uploader.addFile({
        source: 'file input',
        name: file.name,
        type: file.type,
        data: file
      })
    })

    event.target.value = ''
  }

  if (uploading) {
    return (
      <FileInputButton buttonClass={buttonClass} disabled>
        <AnimatedSpinnerSvg className={`icon ${iconColorClass} u-mrgn-r`} />
        Uploading
      </FileInputButton>
    )
  }
  return (
    <FileInputButton handleUpload={handleUpload} buttonClass={buttonClass}>
      {includeIcon &&
            (
              <span className='icon icon--add u-mrgn-r'>
                <AddSvg />
              </span>
            )}
      {label}
    </FileInputButton>
  )
}

FileInput.defaultProps = {
  includeIcon: false,
  label: ' Upload a file',
  uploading: false,
  iconColorClass: ''
}

FileInput.propTypes = {
  uploader: PropTypes.object.isRequired,
  buttonClass: PropTypes.string.isRequired,
  includeIcon: PropTypes.bool,
  label: PropTypes.string,
  uploading: PropTypes.bool,
  iconColorClass: PropTypes.string
}

export default FileInput
